import React, { useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { Link } from "react-router-dom";
import { Icon } from "../../../../components/Component";
import { useThemeUpdate } from "../../../provider/Theme";

const AppDropdown = () => {
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen(!open);
  };
  return (
    <Dropdown isOpen={open} toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#dropdown"
        onClick={(ev) => ev.preventDefault()}
        className="dropdown-toggle nk-quick-nav-icon"
      >
        <div className="icon-status icon-status-na">
          <Icon name="menu-circled"></Icon>
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-lg">
        <div className="dropdown-body">
          <ul className="list-apps">
            {/* Dashboard */}
            <li>
              <Link to={`${process.env.PUBLIC_URL}/`} onClick={toggle}>
                <span className="list-apps-media">
                  <Icon name="dashboard" className="bg-primary text-white"></Icon>
                </span>
                <span className="list-apps-title">Dashboard</span>
              </Link>
            </li>
            {/* Add event */}
            <li>
              <Link to={`${process.env.PUBLIC_URL}/event-list`} onClick={toggle}>
                <span className="list-apps-media">
                  <Icon name="list-thumb-alt" className="bg-primary text-white"></Icon>
                </span>
                <span className="list-apps-title">Event list</span>
              </Link>
            </li>
            <li>
              <Link to={`${process.env.PUBLIC_URL}/project-card`} onClick={toggle}>
                <span className="list-apps-media">
                  <Icon name="icon ni ni-clipboad-check" className="bg-primary text-white"></Icon>
                </span>
                <span className="list-apps-title">Subscription Plans</span>
              </Link>
            </li>
            <li>
              <Link to={`${process.env.PUBLIC_URL}/site-setting`} onClick={toggle}>
                <span className="list-apps-media">
                  <Icon name="icon ni ni-setting-fill" className="bg-primary text-white"></Icon>
                </span>
                <span className="list-apps-title">Site Setting</span>
              </Link>
            </li>
            <li>
              <Link to={`${process.env.PUBLIC_URL}/company-list`} onClick={toggle}>
                <span className="list-apps-media">
                  <Icon name="icon ni ni-property-alt" className="bg-primary text-white"></Icon>
                </span>
                <span className="list-apps-title">Company List</span>
              </Link>
            </li>
            <li>
              <Link to={`${process.env.PUBLIC_URL}/payment-details`} onClick={toggle}>
                <span className="list-apps-media">
                  <Icon name="icon ni ni-template-fill" className="bg-primary text-white"></Icon>
                </span>
                <span className="list-apps-title">Payment Details</span>
              </Link>
            </li>
            <li>
              <Link to={`${process.env.PUBLIC_URL}/contact-list`} onClick={toggle}>
                <span className="list-apps-media">
                  <Icon name="list-index-fill" className="bg-primary text-white"></Icon>
                </span>
                <span className="list-apps-title">Contact List</span>
              </Link>
            </li>
          </ul>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default AppDropdown;
