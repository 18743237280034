import React, { useEffect, useState } from "react";
import { Icon, Button, Col, RSelect } from "../../../components/Component";
import { Modal, ModalBody, Form, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import Required from "../../components/Required";
import PuffLoader from "react-spinners/PuffLoader";

const FormModal = ({ modal, closeModal, onSubmit, formData, setFormData, modalType, setLoading, loading }) => {
  useEffect(() => {
    reset(formData);
  }, [formData]);

  const {
    reset,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (formData) {
      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key]);
      });
      setPlanStates(formData.status === "1");
    }
  }, [formData, setValue]);
  const defaultOptions = [
    { value: "m", label: "Monthly" },
    { value: "y", label: "Yearly" },
  ];
  const [checkData, setCheckData] = useState({});

  const handleCheckboxChange = (event, feature) => {
    const { checked } = event.target;
    setCheckData((prevFormData) => ({
      ...prevFormData,
      [feature.key]: checked ? "yes" : "no",
    }));
  };
  const [planStates, setPlanStates] = useState(false);

  const handleToggle = () => {
    setPlanStates((prevState) => !prevState);
    setFormData((prevFormData) => ({
      ...prevFormData,
      status: planStates ? "0" : "1",
    }));
  };

  const blockInvalidChar = (e) => {
    const inputValue = e.target.value;
    if (
      inputValue.length >= 5 &&
      e.keyCode !== 8 &&
      e.keyCode !== 46 &&
      e.keyCode !== 37 &&
      e.keyCode !== 39 &&
      e.keyCode !== 17 &&
      e.keyCode !== 65 &&
      e.keyCode !== 9 &&
      e.keyCode !== 86 &&
      e.keyCode !== 88 &&
      e.keyCode !== 67
    ) {
      e.preventDefault();
    }
  };

  const checkKeys = (e) => {
    const inputValue = e.target.value;
    let invalidChars = ["-", "e", "E", ".", "+"];
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  const blockInvalidCharEmp = (e) => {
    const inputValue = e.target.value;
    if (
      inputValue.length >= 5 &&
      e.keyCode !== 8 &&
      e.keyCode !== 46 &&
      e.keyCode !== 37 &&
      e.keyCode !== 39 &&
      e.keyCode !== 17 &&
      e.keyCode !== 65 &&
      e.keyCode !== 9 &&
      e.keyCode !== 86 &&
      e.keyCode !== 88 &&
      e.keyCode !== 67
    ) {
      e.preventDefault();
    }
  };

  const handleSetDiscountPrice = (e) => {
    //Discount Percentage Variable
    const inputVal = parseFloat(e.target.value);
    const upgradedVal = inputVal <= 0 ? 0 : inputVal;
    console.log(e.target.value);
    //Discounted Amount Variable
    const planPrice = parseFloat(formData?.price);
    const Total = (planPrice / 100) * inputVal;
    if (!e.target.value || e.target.value === 0) {
      setFormData({ ...formData, discount: "", discount_price: "" });
    } else {
      setFormData({ ...formData, discount: upgradedVal, discount_price: Total });
    }
  };

  return (
    <Modal isOpen={modal} toggle={() => closeModal()} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">
            {modalType === "add" && "Add Plan"} {modalType === "edit" && "Update Plan"}
          </h5>
          <div className="mt-4">
            <Form className="row gy-4" onSubmit={handleSubmit(onSubmit)}>
              <Col>
                <div className="form-group">
                  <label className="form-label">Plan Title</label>
                  <Required />
                  <input
                    type="text"
                    {...register("name", {
                      required: "This field is required",
                      pattern: {
                        value: /^[A-Za-z\s]+$/,
                        message: "Only alphabets and spaces are allowed",
                      },
                      minLength: {
                        value: 3,
                        message: "Please enter at least 3 characters",
                      },
                    })}
                    maxLength={30}
                    value={formData.name}
                    placeholder="Enter plan title"
                    onChange={(e) => {
                      const capitalizedValue = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
                      setFormData({ ...formData, name: capitalizedValue });
                    }}
                    className="form-control"
                  />
                  {errors.name && <span className="invalid">{errors.name.message}</span>}
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Rate Of Plan ($)</label>
                  <Required />
                  <input
                    type="number"
                    onKeyDown={blockInvalidChar}
                    {...register("rate", { required: "This field is required" })}
                    value={formData.price}
                    placeholder="Enter price"
                    onChange={(e) => {
                      const inputVal = parseInt(e.target.value);
                      const upgradedVal = inputVal <= 1 ? 1 : inputVal;
                      setFormData({ ...formData, price: upgradedVal });
                    }}
                    className="form-control"
                  />
                  {errors.tasks && <span className="invalid">{errors.price.message}</span>}
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Select Plan Subscription</label>
                  <Required />
                  <select
                    name="durationtype"
                    id=""
                    selected
                    onChange={(e) => {
                      setFormData({ ...formData, durationtype: e.target.value });
                    }}
                    className="form-select"
                    defaultValue={"m"}
                    value={formData.durationtype}
                  >
                    {defaultOptions.map((item, index) => (
                      <option key={item.value} value={item.value} selected={formData.durationtype === item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Enter Discount %</label>
                  <input
                    type="number"
                    onKeyDown={blockInvalidChar}
                    {...register("discount", {})}
                    value={formData.discount}
                    placeholder="Enter discount percentage"
                    onChange={(e) => handleSetDiscountPrice(e)}
                    className="form-control"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Discounted Price ($)</label>
                  <input
                    disabled
                    type="number"
                    {...register("discount_price", {})}
                    value={formData.discount_price}
                    placeholder="Auto calculate discount price"
                    className="form-control"
                  />
                </div>
              </Col>
              <Col md="12" className="mt-4">
                <div className="d-flex justify-content-between">
                  <div className="fs-4 fw-bold">Plan Features</div>
                </div>
              </Col>
              <Col md="6" className="mt-2">
                <div className="form-group">
                  <label className="form-label">Business Description Limit</label>
                  <input
                    type="number"
                    onKeyDown={blockInvalidCharEmp}
                    {...register("description", { required: "This field is required" })}
                    value={formData.description}
                    placeholder="Enter Description Limit"
                    onChange={(e) => {
                      const inputDuration = parseInt(e.target.value);
                      const durationValue = inputDuration < 0 ? 0 : inputDuration;
                      setFormData({ ...formData, description: durationValue });
                    }}
                    className="form-control"
                  />
                  {errors.description && <span className="invalid">{errors.description.message}</span>}
                </div>
              </Col>
              <Col md="6" className="mt-2">
                <div className="form-group">
                  <label className="form-label">Add Photos Limit</label>
                  <input
                    type="number"
                    onKeyDown={blockInvalidCharEmp}
                    {...register("photos", { required: "This field is required" })}
                    value={formData.photos}
                    placeholder="Enter photos count"
                    onChange={(e) => {
                      const inputDuration = parseInt(e.target.value);
                      const durationValue = inputDuration < 0 ? 0 : inputDuration;
                      setFormData({ ...formData, photos: durationValue });
                    }}
                    className="form-control"
                  />
                  {errors.photos && <span className="invalid">{errors.photos.message}</span>}
                </div>
              </Col>
              <Col md="6" className="mt-2">
                <div className="form-group">
                  <label className="form-label">Enter Upload Video Limit</label>
                  <input
                    type="number"
                    onKeyDown={blockInvalidChar}
                    {...register("upload_vid", {
                      pattern: {
                        value: /^\d+$/,
                        message: "Only digits are allowed",
                      },
                    })}
                    value={formData.upload_vid}
                    placeholder="Enter count for Upload Video"
                    onChange={(e) => {
                      const inputDuration = parseInt(e.target.value);
                      const durationValue = inputDuration < 0 ? 0 : inputDuration;
                      setFormData({ ...formData, upload_vid: durationValue });
                    }}
                    className="form-control"
                  />
                  {errors.upload_vid && <span className="invalid">{errors.upload_vid.message}</span>}
                </div>
              </Col>
              <Col md="6" className="mt-2">
                <div className="form-group">
                  <label className="form-label">Enter Upload Youtube Video Limit</label>
                  <input
                    type="text"
                    {...register("upload_yt", {
                      pattern: {
                        value: /^[A-Za-z0-9]+$/, // Adjusted pattern to allow letters and digits
                        message: "Only letters (A-Z, a-z) and digits (0-9) are allowed",
                      },
                    })}
                    value={formData.upload_yt}
                    placeholder="Enter count for Upload Youtube Video"
                    onChange={(e) => {
                      setFormData({ ...formData, upload_yt: e.target.value });
                    }}
                    className="form-control"
                  />
                  {errors.upload_yt && <span className="invalid">{errors.upload_yt.message}</span>}
                </div>
              </Col>
              <Col md="6" className="mt-2">
                <div className="form-group">
                  <label className="form-label">Enter Mixers Limit</label>
                  <input
                    type="number"
                    onKeyDown={blockInvalidChar}
                    {...register("mixers", {
                      pattern: {
                        value: /^\d+$/,
                        message: "Only digits are allowed",
                      },
                    })}
                    value={formData.mixers}
                    placeholder="Enter count for Mixers"
                    onChange={(e) => {
                      const inputDuration = parseInt(e.target.value);
                      const durationValue = inputDuration < 0 ? 0 : inputDuration;
                      setFormData({ ...formData, mixers: durationValue });
                    }}
                    className="form-control"
                  />
                  {errors.mixers && <span className="invalid">{errors.mixers.message}</span>}
                </div>
              </Col>
              <Col md="6" className="mt-2">
                <div className="form-group">
                  <label className="form-label">Enter Social Media URL's Limit</label>
                  <input
                    type="text"
                    {...register("social_url", {
                      pattern: {
                        value: /^[A-Za-z0-9]+$/, // Adjusted pattern to allow letters and digits
                        message: "Only letters (A-Z, a-z) and digits (0-9) are allowed",
                      },
                    })}
                    value={formData.social_url}
                    placeholder="Enter count for Social Media URL's"
                    onChange={(e) => {
                      setFormData({ ...formData, social_url: e.target.value });
                    }}
                    className="form-control"
                  />
                  {errors.social_url && <span className="invalid">{errors.social_url.message}</span>}
                </div>
              </Col>
              <Col md="6" className="mt-2">
                <div className="form-group">
                  <label className="form-label">Enter Service Listings Limit</label>
                  <input
                    type="text"
                    {...register("service", {
                      required: "This field is required",
                    })}
                    value={formData.service}
                    placeholder="Enter count for Service Listings"
                    onChange={(e) => {
                      setFormData({ ...formData, service: e.target.value });
                    }}
                    className="form-control"
                  />
                  {errors.service && <span className="invalid">{errors.service.message}</span>}
                </div>
              </Col>
              <Col md="6" className="mt-2">
                <div className="form-group">
                  <label className="form-label">Enter Customized Contact Form Limit</label>
                  <input
                    type="number"
                    onKeyDown={blockInvalidChar}
                    {...register("contact_frm", {
                      pattern: {
                        value: /^\d+$/,
                        message: "Only digits are allowed",
                      },
                    })}
                    value={formData.contact_frm}
                    placeholder="Enter Contact Form for count"
                    onChange={(e) => {
                      const inputDuration = parseInt(e.target.value);
                      const durationValue = inputDuration < 0 ? 0 : inputDuration;
                      setFormData({ ...formData, contact_frm: durationValue });
                    }}
                    className="form-control"
                  />
                  {errors.contact_frm && <span className="invalid">{errors.contact_frm.message}</span>}
                </div>
              </Col>
              <Col md="6" className="mt-2">
                <div className="form-group">
                  <label className="form-label">Enter Go-Live Streaming Limit</label>
                  <Required />
                  <input
                    type="text"
                    {...register("streaming", {
                      required: "This field is required",
                    })}
                    value={formData.streaming}
                    placeholder="Enter count for Streaming"
                    onChange={(e) => {
                      setFormData({ ...formData, streaming: e.target.value });
                    }}
                    className="form-control"
                  />
                  {errors.streaming && <span className="invalid">{errors.streaming.message}</span>}
                </div>
              </Col>
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="md" type="submit" disabled={loading}>
                      {modalType === "add" && "Add Plan"} {modalType === "edit" && "Update Plan"}
                      {loading ? <PuffLoader speedMultiplier="2" size="30" color={"#39ff00"} /> : ""}
                    </Button>
                  </li>
                  <li>
                    <Button
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-primary"
                    >
                      Cancel
                    </Button>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default FormModal;
