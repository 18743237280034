import React, { useEffect, useState } from "react";
import { Block, BlockHead, BlockHeadContent, BlockTitle, PreviewCard, Icon } from "../../components/Component";
import Content from "../../layout/content/Content";
import { getCookie } from "../../utils/Utils";
import CustomizeReactDataTable from "./CustomizeReactDataTable";
import moment from "moment";

export const NotAvailable = () => <div style={{ textAlign: "center" }}>----</div>;

const PaymentDetails = () => {
  const [companyData, setCompanyData] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [recordLimit, setRecordLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  //console.log('pageNo: ', pageNo, typeof pageNo)

  const fetchData = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("page", pageNo);
      formData.append("limit", recordLimit);
      formData.append("search", search);
      //console.log('page No.', pageNo);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/admin/get-payment-details`,
        {
          method: "POST",
          headers: { authToken: getCookie("authToken") },
          body: formData,
        }
      );

      const responseData = await response.json();
      // console.log("Fetched Data:", responseData?.data); // Log fetched data
      // console.log("Total Records from API:", responseData?.pagination?.total);

      if (responseData?.data) {
        setCompanyData(responseData.data);
        setTotalRecords(responseData?.pagination?.total || 0);
      } else {
        setCompanyData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pageNo, recordLimit, search]);

  const Status = ({ status }) => {
    const parsedStatus = Number(status);

    if (parsedStatus === 1) {
      return (
        <div className="d-flex align-items-center" style={{ color: "blue" }}>
          <Icon name="icon ni ni-dot" style={{ fontSize: "30px" }} />
          Initiated
        </div>
      );
    }
    if (parsedStatus === 2) {
      return (
        <div className="d-flex align-items-center text-warning">
          <Icon name="icon ni ni-dot" style={{ fontSize: "30px" }} />
          Pending
        </div>
      );
    }
    if (parsedStatus === 3) {
      return (
        <div className="d-flex align-items-center text-success">
          <Icon name="icon ni ni-dot" style={{ fontSize: "30px" }} />
          Success
        </div>
      );
    }
    if (parsedStatus === 4) {
      return (
        <div className="d-flex align-items-center text-danger">
          <Icon name="icon ni ni-dot" style={{ fontSize: "30px" }} />
          Failed
        </div>
      );
    }

    return (
      <div className="d-flex align-items-center text-warning">
        <Icon name="icon ni ni-alert" style={{ fontSize: "14px" }} />
        Unknown
      </div>
    );
  };

  const dataTableColumns = [
    {
      name: "Sr No",
      selector: (row, index) => (pageNo * recordLimit) + index + 1 || <NotAvailable />,
    },
    {
      name: "Company Name",
      selector: (row) => row?.company_name || <NotAvailable />,
    },
    {
      name: "Plan Name",
      selector: (row) => row?.plan_name || <NotAvailable />,
    },
    {
      name: "Payment Method",
      selector: (row) =>
        row?.mode === "3" ? "Paypal" : row?.mode === "1" ? "Stripe" : <NotAvailable />,
    },
    {
      name: "Amount",
      cell: (row) => `$ ${row?.amount}` || <NotAvailable />,
    },
    {
      name: "Payment Status",
      cell: (row) => <Status status={row?.status} />,
    },
    {
      name: "Date",
      selector: (row) =>
        row?.created_at ? moment(row.created_at).format("DD MMM, YYYY, h:mm A") : <NotAvailable />,
    },
  ];

  const onChangePage = (page) => {
    setPageNo(page);
  };
  
  const onChangeRowsPerPage = (newLimit) => {
    setRecordLimit(newLimit);
    setPageNo(0); 
  };

  return (
    <>
      <Content>
        <Block size="lg">
          <BlockHead>
            <BlockHeadContent>
              <div className="d-flex flex-row align-center justify-between">
                <BlockTitle tag="h4">Payment Details</BlockTitle>
              </div>
            </BlockHeadContent>
          </BlockHead>
          <PreviewCard>
            <CustomizeReactDataTable
              data={companyData}
              columns={dataTableColumns}
              searchBy={"company_name"}
              pagination
              actions
              loading={loading}
              setSearch={setSearch}
              totalRecords={totalRecords}
              pageNo={pageNo}
              recordLimit={recordLimit}
              setRecordLimit={setRecordLimit}
              activePage={pageNo}
              setPageNo={setPageNo}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
            />
          </PreviewCard>
        </Block>
      </Content>
    </>
  );
};

export default PaymentDetails;
